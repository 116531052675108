<script setup lang="ts">
// Cookiebot implementation reference
// https://support.cookiebot.com/hc/en-us/articles/360003798814-Changing-or-withdrawing-consent

// COMPOSABLES
const { tt } = useTypedI18n();
const { navMenuItems } = useNavMenuItems();
const gtmEnabled = useRuntimeConfig().public.gtm.enabled;

// DATA
const year = new Date().getFullYear();

// HANDLERS
const top = (): void => scrollToTop();
</script>

<template>
    <footer class="bg-primary-950 flex w-full justify-center text-white">
        <BaseWidthContainer class="flex flex-col gap-8 py-8 md:gap-9 md:py-12 xl:py-16">
            <div class="flex flex-col gap-8">
                <div class="grid grid-cols-2 gap-x-5 gap-y-8 md:grid-cols-3 md:gap-4 xl:gap-8">
                    <div class="col-span-2 grid grid-cols-2 gap-x-5 gap-y-4 md:col-span-1 md:flex md:flex-col md:gap-4">
                        <div class="col-span-2">
                            <SvgoFleequidLogo class="h-6" :font-controlled="false" />
                        </div>
                        <div class="text-xs text-neutral-400">
                            Adorea SRL<br />
                            Via provinciale per Bizzarone, <br />
                            43, Olgiate Comasco (CO)<br />
                            22077 <br />
                            {{ tt('footer.vat') }}: 04114790134<br />
                        </div>
                        <div class="flex flex-col gap-2 text-xs">
                            <h4 class="font-semibold">{{ tt('footer.support') }}</h4>
                            <div class="flex gap-1">
                                <UIcon name="i-heroicons-phone-solid" class="h-4 w-4" />
                                <NuxtLinkLocale to="tel:+390287176063" class="underline"> +39 0287176063 </NuxtLinkLocale>
                            </div>
                            <div class="flex gap-1">
                                <UIcon class="h-4 w-4" name="i-mdi-email-lock-outline" />
                                <NuxtLinkLocale to="mailto:adorea@pecaruba.it" class="underline" target="_blank"> adorea@pecaruba.it </NuxtLinkLocale>
                            </div>
                            <div class="flex gap-1">
                                <UIcon class="h-4 w-4" name="i-mdi-email-outline" />
                                <NuxtLinkLocale to="mailto:info@fleequid.com" class="underline" target="_blank"> info@fleequid.com </NuxtLinkLocale>
                            </div>
                        </div>
                    </div>
                    <nav class="flex flex-col gap-3">
                        <h4 class="text-base font-semibold md:text-lg">{{ tt('footer.explore') }}</h4>
                        <NuxtLinkLocale
                            v-for="item in navMenuItems"
                            :key="item.label"
                            class="text-xs hover:underline md:text-sm"
                            :to="item.to"
                            :target="item.isExternal ? '_blank' : undefined"
                        >
                            {{ item.label }}
                        </NuxtLinkLocale>
                    </nav>
                    <nav class="flex flex-col gap-3">
                        <h4 class="text-base font-semibold md:text-lg">{{ tt('footer.legal') }}</h4>
                        <NuxtLinkLocale to="terms-and-conditions" class="text-xs hover:underline md:text-sm">
                            {{ tt('termsConditions.link') }}
                        </NuxtLinkLocale>
                        <NuxtLinkLocale to="privacy-policy" class="text-xs hover:underline md:text-sm">{{ tt('privacy.link') }}</NuxtLinkLocale>
                        <NuxtLinkLocale to="cookie-policy" class="text-xs hover:underline md:text-sm">
                            {{ tt('footer.cookiePolicy.base') }}
                        </NuxtLinkLocale>
                        <template v-if="gtmEnabled">
                            <a class="text-xs hover:underline md:text-sm" href="javascript:Cookiebot.renew()">
                                {{ tt('footer.cookiePolicy.changeConsent') }}
                            </a>
                            <a class="text-xs hover:underline md:text-sm" href="javascript:Cookiebot.withdraw()">
                                {{ tt('footer.cookiePolicy.revokeConsent') }}
                            </a>
                        </template>
                    </nav>
                </div>
            </div>
            <UDivider class="opacity-20" />
            <div class="flex items-start justify-between sm:items-center">
                <div class="flex flex-col items-start gap-3 sm:flex-row sm:items-center">
                    <p class="text-xs text-neutral-400">© {{ year }} Fleequid. All Rights Reserved.</p>
                </div>
                <UButton variant="link" color="white" size="xs" @click="top">{{ tt('footer.backTop') }}</UButton>
            </div>
        </BaseWidthContainer>
    </footer>
</template>
